import './location-marquee.scss';
import { gsap } from 'gsap/dist/gsap';

class LocationMarquee {
    constructor($element) {
        this.$locationMarqueeRoot = $element;

        this.$isReducedMotion = window.matchMedia(
            '(prefers-reduced-motion: reduce)'
        ).matches;

        this.$locationMarqueeWrapper =
            this.$locationMarqueeRoot.querySelectorAll(
                '.location-marquee__items-wrapper'
            );

        this.$locationMarqueeWrapper.forEach((el) => {
            const marquee = el.querySelector('.location-marquee__items');

            const rate = this.$isReducedMotion ? 0 : 80; // the higher the value, the faster it is
            const distance = marquee.clientWidth;
            const style = window.getComputedStyle(marquee);
            const marginRight = parseInt(style.marginRight) || 0;
            const totalDistance = distance + marginRight;

            const time = totalDistance / rate;
            const container = marquee.parentElement;

            const isLeft = marquee.dataset.direction === 'l';

            const playAnimation = () => {
                gsap.to(container, time, {
                    repeat: -1,
                    x: (isLeft ? '-' : '') + totalDistance,
                    ease: 'linear',
                    delay: isLeft ? 0 : 1,
                    autoAlpha: 1
                });
            };

            const pauseAnimation = () => {
                gsap.killTweensOf(container);
            };

            // stop animation if any element in container or its descendants is hovered
            container.addEventListener('mouseover', () => {
                pauseAnimation();
            });
            container.addEventListener('mouseout', () => {
                playAnimation();
            });

            // stop animation if any element in container or its descendants has keyboard focus
            container.addEventListener('focusin', (e) => {
                pauseAnimation();
                
                // Find the focused element's position
                const focusedElement = e.target;
                const elementRect = focusedElement.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();
                
                // Calculate the scroll position to center the focused element
                const scrollTo = elementRect.left - containerRect.left - (window.innerWidth / 2) + (elementRect.width / 2);
                
                // Animate to the position
                gsap.to(container, {
                    duration: 0.5,
                    x: -scrollTo,
                    ease: 'power2.out'
                });
            });

            container.addEventListener('focusout', (e) => {
                // Only restart animation if focus is moving outside the container
                if (!container.contains(e.relatedTarget)) {
                    // Reset position and restart animation
                    gsap.to(container, {
                        duration: 0.5,
                        x: 0,
                        ease: 'power2.out',
                        onComplete: () => {
                            playAnimation();
                        }
                    });
                }
            });
            playAnimation();
        });
    }
}

export { LocationMarquee };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $locationMarquees = $context.querySelectorAll(
            '[data-location-marquee="root"]'
        );

        if ($locationMarquees.length > 0) {
            for (let i = 0; i < $locationMarquees.length; i++) {
                new LocationMarquee($locationMarquees[i]);
            }
        }
    }
});
